
import {stats} from "../constants"
import styles from "../style"



const variante1 ={
  hidden:{
    x:"-100vh"
    
  },
  show:{
  
    x:0,
    transition:{duration:2 ,repeat: Infinity, repeatDelay: 5}
  }
}

const Stats = () => (
    <section className={`${styles.flexCenter} flex flex-wrap sm:mb-20 mb-6`}>
     {stats.map((stat)=>(<div key={stat.id} className={` flex-1 flex justify-start items-center flex-row m-3 `}>
      <h4
       className="font-poppins font-semibold xs:text-[40px] text-[30px] xs:leading-[53px] leading-[43px] text-white ">{stat.value}</h4>
      <p className="font-poppins font-normal xs:text-[20px] text-[15px] xs:leading-[26px] leading-[21px] ml-3 text-gradient uppercase ">{stat.title}</p>
     </div>))}
    </section>
  )


export default Stats